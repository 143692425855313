import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const userString = localStorage.getItem("ijeDetails");
const user = userString ? JSON.parse(userString) : null;

const initialState = {
  firstName: user?.firstName ?? "",
  email: user?.email ?? "",
  imageString: user?.imageString ?? "",
  address: user?.address ?? "",
  isVerified: user?.isVerified ?? "",
  lastName: user?.lastName ?? "",
  phoneNumber: user?.phoneNumber ?? "",
  acceptanceStatus: user?.acceptanceStatus ?? "",
  acceptanceStatusReasons: user?.acceptanceStatusReasons ?? "",
  accountLock: user?.accountLock ?? "",
  accountStatus: user?.accountStatus ?? "",
  educationInformation: user?.educationInformation ?? {},
  id: user?.id ?? "",
  roles: user?.roles ?? [],
  userType: user?.userType ?? "",
  walletDetailsResponse: user?.walletDetailsResponse ?? {},
  bvn: user?.bvn ?? "",
  bvnValidate: user?.bvnValidate ?? false,
  gender: user?.gender?? "", 
  dob: user?.dob ??""

  // permissions: user?.role?.permissions?.map((p) => p.action) ?? []
};

const authSlice = createSlice({
  name: "auth",

  initialState,
  reducers: {
    setUser: (state, action) => {
      state.firstName = action.payload.firstName;
      state.email = action.payload.email;
      state.address = action.payload.address;
      state.imageString = action.payload.imageString;
      state.isVerified = action.payload.isVerified;
      state.lastName = action.payload.lastName;
      state.phoneNumber = action.payload.phoneNumber;
      state.acceptanceStatus = action.payload.acceptanceStatus;
      state.acceptanceStatusReasons = action.payload.acceptanceStatusReasons;
      state.accountLock = action.payload.accountLock;
      state.accountStatus = action.payload.accountStatus;
      state.educationInformation = action.payload.educationInformation;
      state.walletDetailsResponse = action.payload.walletDetailsResponse;
      state.id = action.payload.id;
      state.roles = action.payload.roles;
      state.userType = action.payload.userType;
      state.bvn = action.payload.bvn;
      state.bvnValidate = action.payload.bvnValidate;
      state.dob = action.payload.dob;
      state.gender = action.payload.gender
    },
    clearUser: (state) => {
      state.firstName = "";
      state.email = "";
      state.address = "";
      state.imageString = "";
      state.isVerified = "";
      state.lastName = "";
      state.phoneNumber = "";
      state.acceptanceStatus = "";
      state.acceptanceStatusReasons = "";
      state.accountLock = "";
      state.accountStatus = "";
      state.educationInformation = {};
      state.walletDetailsResponse = {};
      state.id = "";
      state.roles = [];
      state.userType = "";
      state.bvn = "";
      state.bvnValidate = false;
      state.gender = '';
      state.dob = '';
    },
  },
});

export const { setUser, clearUser } = authSlice.actions;
export default authSlice.reducer;
