import SideBar from "./SideBar";
import { ReactComponent as Icon } from "../Images/money.svg";
import { ReactComponent as Search } from "../Images/search.svg";
import { ReactComponent as Completed } from "../Images/complete.svg";
import cancel from "../Images/Cancel.svg";
import { useEffect, useState } from "react";
import Footer from "./Footer";
import LoanManagementTable from "./component/LoanManagementTable";
import LoanRequest from "../../utils/services/loanRequests";
import { CurrencyFormatter } from "../../utils/helpers/helper";
import { toast } from "react-toastify";

export default function LoanManagement() {
  const [modal, setModal] = useState(false);
  const [isTotalDisbursed, setTotalDisbursed] = useState(0);
  const [isLoans, setLoans] = useState([]);
  const [isSelected, setSelected] = useState({});
  const [isLoading, setLoading] = useState(false);
  const getAllLoans = async () => {
    await LoanRequest.getAllLoans().then((res) => {
      setLoans(res.data.result);
    });
  };

  const getTotalDisbursed = async () => {
    await LoanRequest.getTotalDisbursed().then((res) => {
      setTotalDisbursed(res.data.result || 0);
    });
  };

  useEffect(() => {
    getAllLoans();
    getTotalDisbursed();
  }, []);
  const loanOverview = [
    {
      id: 1,
      name: "Total Loan Disbursed",
      icon: <Icon />,
      amount: CurrencyFormatter(parseFloat(isTotalDisbursed || 0), "$"),
      back: "#d5efff",
      border: "#E8F7FF",
      text: "#0C4AFF",
    },
    {
      id: 2,
      name: "Active Loans",
      icon: <Icon />,
      amount: `0`,
      back: "#dff9e4",
      border: "#f1fcf2",
      text: "#1f7634",
    },
    {
      id: 3,
      name: "Total Repaid",
      icon: <Icon />,
      amount: `0`,
      back: "#fee6e5",
      border: "#FEF2F2",
      text: "#b7192c",
    },
    {
      id: 4,
      name: "Outstanding Amount",
      icon: <Icon />,
      amount: `0`,
      back: "#cafdf3",
      border: "#effefa",
      text: "#0A7B75",
    },
    {
      id: 5,
      name: "Delinquency Rate",
      icon: <Icon />,
      amount: (
        <div className=" flex justify-start items-center gap-[4px] text-[#1f7634] text-xl font-semibold ">
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8008 16.6654L10.8008 3.33203" stroke="#32B550" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.9661 12.4974C14.9661 12.4974 11.8974 16.6641 10.7994 16.6641C9.70145 16.6641 6.63281 12.4974 6.63281 12.4974" stroke="#32B550" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          10%
        </div>
      ),
      back: "#d5efff",
      border: "#E8F7FF",
      text: "#0C4AFF",
    },
  ];

  const openFunc = (item) => {
    // console.log(item)
    setSelected(item);
    setModal(true);
  };

  const approveLoan = async () => {
    setLoading(true);
    await LoanRequest.approveLoan(isSelected?.email).then((res) => {
      setLoading(false);
      if (res.data.statusCode === 200) {
        toast.success("successful", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setModal(false);
      } else {
        toast.error(res?.data?.message || "an error occures", {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
      }
    });
  };

  const rejectLoan = async () => {
    setLoading(true);
    await LoanRequest.rejectLoan(isSelected?.email).then((res) => {
      setLoading(false);
      if (res.data.statusCode === 200) {
        toast.success("successful", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setModal(false);
      } else {
        toast.error(res?.data?.message || "an error occures", {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
      }
    });
  };
  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[362px]">
        <div className="flex gap-[32px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <h3 className="self-stretch  text-[#3a3c3f] text-2xl font-medium leading-[38px]">Loan Management</h3>

          {/* LOAN CARDS */}
          <div className=" w-full flex-col justify-start items-center gap-[8px] inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">Loan Overview</h5>
            <div className=" w-full flex-1 flex 100:flex-wrap gap-[16px] xl:flex-nowrap justify-start items-start">
              {loanOverview.map((item) => (
                <div key={item.id} className=" h-[166px]  flex-grow w-[202.6px] fl p-6 bg-white rounded-lg shadow border border-[#cdd1d4] flex-col justify-start items-start gap-6 inline-flex">
                  <div
                    style={{
                      color: item.text,
                      backgroundColor: item.back,
                      borderColor: item.border,
                    }}
                    className={`w-10 h-10  flex justify-center items-center rounded-[28px]  border-4 `}
                  >
                    <span className="flex justify-center items-center w-[20px] h-[20px]">{item.icon}</span>
                  </div>
                  <div className=" w-fit flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className="text-[#6C757D] text-sm font-normal leading-tight">{item.name}</h4>
                    <h3 className="text-[#4A4F54] whitespace-nowrap text-2xl font-semibold ">{item.amount}</h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* TABLE 1 */}

          <div className=" w-full gap-[8px] flex-col justify-start items-center  inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">Loan Applications</h5>

            <LoanManagementTable data={isLoans} onClick={openFunc} />
          </div>
          <Footer />
        </div>
      </div>
      {modal && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div onClick={() => setModal(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full  flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96  h-[500px] overflow-y-scroll p-[24px]  bg-white rounded-xl shadow ">
                <div className="gap-[32px] h-fit w-full flex-col justify-center items-center flex">
                  <div
                    style={{
                      color: "#0C4AFF",
                      backgroundColor: "#d5efff",
                      borderColor: "#E8F7FF",
                    }}
                    className={`w-12 h-12  flex justify-center items-center rounded-[28px]  border-4 `}
                  >
                    <Icon />
                  </div>
                  <h4 className=" text-[18px] font-medium text-[#3A3C3F]">Loan Details</h4>
                  <div className=" w-full flex-col justify-start items-center gap-5 inline-flex">
                    {/* <img src={campaign} alt="" />*/}
                    <div className="w-full flex-col justify-start items-start gap-6 flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                        <h4 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Application ID</h4>
                        <h5 className="self-stretch text-[#4a4f54] text-sm font-medium  leading-tight">{isSelected?.id}</h5>
                      </div>
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                        <h4 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Applicant</h4>
                        <h5 className="self-stretch text-[#4a4f54] text-sm font-medium  leading-tight capitalize">{isSelected?.name}</h5>
                      </div>{" "}
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                        <h4 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Amount Requested </h4>
                        <h5 className="self-stretch text-[#4a4f54] text-sm font-medium  leading-tight">{CurrencyFormatter(isSelected?.amount|| 0, "$")}</h5>
                      </div>
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                        <h4 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Date Requested</h4>
                        <h5 className="self-stretch text-[#4a4f54] text-sm font-medium  leading-tight">nil</h5>
                      </div>
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                        <h4 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Comments</h4>
                        <h5 className="self-stretch text-[#4a4f54] text-sm font-medium  leading-tight">nil</h5>
                      </div>
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                        <h4 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Status</h4>
                        <div className="pl-1.5 pr-2 py-0.5 bg-[#f1fcf2] rounded-2xl justify-center items-center gap-1 flex">
                          <Completed />
                          <div className="text-center text-[#1f7634] text-xs font-medium leading-[18px]">{isSelected?.status}</div>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex-col justify-start items-start gap-3 inline-flex">
                    <button onClick={approveLoan} className="h-12 flex-col w-full px-5 py-3 bg-[#0c4cff] rounded-lg shadow border border-[#0c4aff] justify-center items-center gap-2 flex text-white text-base font-medium  leading-normal">
                      {isLoading ? "Loading..." : "Approve"}
                    </button>
                    <button onClick={rejectLoan} className=" border-[#aab0b6] w-full  h-12 px-5 py-3 rounded-lg shadow border  justify-center items-center gap-2 flex text-[#4a4f54] text-base font-medium  leading-normal">
                      {isLoading ? "Loading..." : "Reject"}
                    </button>
                  </div>{" "}
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}
