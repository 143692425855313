import { Navigate } from "react-router-dom";
import { useAppSelector } from "../utils/redux/store";
import { toast } from "react-toastify";

export const UserProtectedRoute = ({ children }) => {
  // const { isAuthenticated, role } = useAuth();
  const { roles } = useAppSelector((state) => state.auth);

  if (roles[0] !== "CUSTOMER") {
    toast.error("Invalid User", {
      position: "top-right",
      autoClose: 2000,
      theme: "colored",
    });
    return <Navigate to="/auth/login" replace />;
  }

  return children;
};


export const AdminProtectedRoute = ({ children }) => {
    // const { isAuthenticated, role } = useAuth();
    const { roles } = useAppSelector((state) => state.auth);
  
    if (!roles.includes("PROCESSOR") && !roles.includes("APPROVER")) {
      toast.error("Session Expired", {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
      });
      return <Navigate to="/auth/login" replace />;
    }
  
    return children;
  };