import { ApiClient } from "./apiConfig";

const apiPath = ApiClient();
const EducationRequest = {
  registerEducation: async (
    email,
    linkedInAddress,
    passportNumber,
    bvn,
    internationalPassport,
    twitterLink,
    instagramLink,
    facebookProfile,
    university,
    certificate,
    transcript,
    schoolAdmitted,
    courseOfStudy,
    country,
    durationOfStudy,
    admissionLetter,
    employmentStatus,
    yearsOfWorkExperience,
    currentEmployer,
    role,
    monthsWithEmployer,
    employmentLetter,
    bankStatement,
    currency,
    tuitionFee,
    livingExpenses,
    totalFundingRequirement,
    amountAvailable
  ) => {
    let payload = {
      email,
      linkedInAddress,
      passportNumber,
      bvn,
      internationalPassport,
      twitterLink,
      instagramLink,
      facebookProfile,
      university,
      certificate,
      transcript,
      schoolAdmitted,
      courseOfStudy,
      country,
      durationOfStudy,
      admissionLetter,
      employmentStatus,
      yearsOfWorkExperience,
      currentEmployer,
      role,
      monthsWithEmployer,
      employmentLetter,
      bankStatement,
      currency,
      tuitionFee,
      livingExpenses,
      totalFundingRequirement,
      amountAvailable,
    };
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(
        process.env.REACT_APP_BASE_URL + "/rest/education/registration",
        payload,
        {
          // headers: {},
          method: "POST",
        }
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
  registerEducationOne: async (
    linkedInAddress,
    passportNumber,
    // bvn,
    internationalPassport,
    twitterLink,
    instagramLink,
    facebookProfile
  ) => {
    let payload = {
      linkedInAddress,
      passportNumber,
      // bvn,
      internationalPassport,
      twitterLink,
      instagramLink,
      facebookProfile,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(
        process.env.REACT_APP_BASE_URL + "/rest/education/registration-one",
        payload,
        {
          // headers: {},
          method: "POST",
        }
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
  registerEducationTwo: async (
    university,
    certificate,
    transcript,
    schoolAdmitted,
    courseOfStudy,
    country,
    durationOfStudy,
    admissionLetter
  ) => {
    let payload = {
      university,
      certificate,
      transcript,
      schoolAdmitted,
      courseOfStudy,
      country,
      durationOfStudy,
      admissionLetter,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .put(
        process.env.REACT_APP_BASE_URL + "/rest/education/registration-two",
        payload,
        {
          // headers: {},
          method: "PUT",
        }
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  registerEducationThree: async (
    email,
    employmentStatus,
    yearsOfWorkExperience,
    currentEmployer,
    role,
    monthsWithEmployer,
    employmentLetter,
    bankStatement
  ) => {
    let payload = {
      email,
      employmentStatus,
      yearsOfWorkExperience,
      currentEmployer,
      role,
      monthsWithEmployer,
      employmentLetter,
      bankStatement,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .put(
        process.env.REACT_APP_BASE_URL + "/rest/education/registration-three",
        payload,
        {
          // headers: {},
          method: "PUT",
        }
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
  registerEducationFour: async (
    email,
    currency,
    tuitionFee,
    livingExpenses,
    totalFundingRequirement,
    amountAvailable,
   
  ) => {
    let payload = {
      email,
    currency,
    tuitionFee,
    livingExpenses,
    totalFundingRequirement,
    amountAvailable,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .put(
        process.env.REACT_APP_BASE_URL + "/rest/education/registration-four",
        payload,
        {
          // headers: {},
          method: "PUT",
        }
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  registerEducationedit: async (
   data
   
  ) => {
    let payload = {
    ...data
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    console.log(payload)

    return await apiPath
      .put(
        process.env.REACT_APP_BASE_URL + "/rest/education/edit",
        payload,
        {
          // headers: {},
          method: "PUT",
        }
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
};

export default EducationRequest;
