import axios from "axios";

const AuthRequest = {
  userLogin: async (email, password) => {
    let payload = {
      email,
      password,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await axios
      .post(process.env.REACT_APP_BASE_URL + "/rest/auth/login", payload, {
        headers: {},
        method: "POST",
      })
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  createAccount: async (
    firstName,
    lastName,
    email,
    createPassword,
    confirmPassword,
    houseAddress,
    photo,
    phoneNumber,
    bvn,
    gender,
    dob,
    terms
  ) => {
    let payload = {
      firstName,
      lastName,
      email,
      createPassword,
      confirmPassword,
      houseAddress,
      photo,
      phoneNumber,
      bvn,
      gender,
      dob,
      terms,
    };
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await axios
      .post(process.env.REACT_APP_BASE_URL + "/rest/auth/user", payload, {
        headers: {},
        method: "POST",
      })
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  confirmOTP: async (otp, email) => {
    let payload = {
      otp,
      email,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await axios
      .post(
        process.env.REACT_APP_BASE_URL + "/rest/auth/verify-registration",
        payload,
        {
          headers: {},
          method: "POST",
        }
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  resednOTP: async (email) => {
    let payload = { email };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await axios
      .post(process.env.REACT_APP_BASE_URL + "/rest/auth/resend-otp", payload, {
        headers: {},
        method: "POST",
      })
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
  forgotPassword: async (email) => {
    let payload = { email: email };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await axios
      .post(
        process.env.REACT_APP_BASE_URL + "/rest/auth/forgot-password",
        payload,
        {
          headers: {},
          method: "POST",
        }
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  resendForgotOTP: async (email) => {
    let payload = { email };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await axios
      .post(
        process.env.REACT_APP_BASE_URL +
          "/rest/auth/resend-otp-forgot-password",
        payload,
        {
          headers: {},
          method: "POST",
        }
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  confirmForgotOTP: async (otp, email) => {
    let payload = {
      otp,
      email,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await axios
      .post(
        process.env.REACT_APP_BASE_URL + "/rest/auth/verify-forgot-password",
        payload,
        {
          headers: {},
          method: "POST",
        }
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  resetPassword: async (newPassword, confirmPassword) => {
    const mail = localStorage.getItem("emailData");
    let payload = {
      email: mail,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await axios
      .post(
        process.env.REACT_APP_BASE_URL + "/rest/auth/password-setup",
        payload,
        {
          headers: {},
          method: "POST",
        }
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
};

export default AuthRequest;
