import { useState } from "react";
import media from "../../Images/media.svg";
import upload from "../../Images/upload.svg";
const FileInput = ({ placeholder, label, error, onChange, name }) => {
  const [isUploaded, setIsUploaded] = useState(false);

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setIsUploaded(true);
    } else {
      setIsUploaded(false);
    }
    onChange(e);
  };
  return (
    <label className=" file-input-container w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor={name}>
      {label}
      <div style={{ width: "100%" }}>
        <input onChange={handleFileChange} type="file" name={name} id={name} className="hidden" />
        <div className={`w-full h-[126px] bg-white border rounded-[8px] flex-col justify-center items-center flex ${isUploaded ? "border-[#0C4AFF]" : "border-[#CDD1D4]"}`}>
          {" "}
          <div className="h-[94px] flex-col justify-center items-center flex">
            <div className="w-10 h-10 p-2.5 bg-[#E5E7E8] rounded-[28px] border-4 border-[#F5F6F6] justify-center items-center inline-flex">
              <img src={upload} alt="upload" />
            </div>
            <div className="self-stretch justify-center items-start gap-1 flex-col flex">
              <div className="justify-center w-full flex-col items-center gap-2 flex">
                <div className="text-[#0C4AFF] text-sm font-normal  ">{isUploaded ? "File Added" : " Click to upload "}</div>
                <div className=" text-center text-[#6C757D] text-xs font-normal  "> {isUploaded ? "" : "(max. 30mb)"}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {selectedImage && (
          <>
            <div className=" mt-[16px] gap-[16px] w-full h-fit border border-[#2F7AFF] p-[16px] justify-start items-start flex rounded-[8px]">
              <div className=" w-[28px] h-[28px] rounded-full bg-[#D5EFFF] border-[4px] flex justify-center items-center border-[#E8F7FF] ">
                <img src={media} alt="" />
              </div>
              <div className="h-16 w-full flex-col justify-start items-start gap-1 inline-flex">
                <div className="self-stretch h-10 flex-col justify-start items-start flex">
                  <div className=" text-[#4a4f54] text-sm font-medium leading-tight">Uploaded File</div>
                </div>
                <div className=" w-full flex-grow justify-start items-center gap-3 inline-flex">
                  <div className=" h-[8px] items-start flex justify-start bg-[#E8F7FF] rounded-[8px] w-full">
                    <div style={{ width: `${progressBar}` }} className={`h-full  bg-[#0C4CFF] rounded-[8px] `}></div>
                  </div>
                  <span className=" text-[#4A4F54] font-medium leading-[24px] text-[12px] ">{progressBar}</span>
                </div>
              </div>
            </div>
          </>
        )} */}
    </label>
  );
};

export default FileInput;
