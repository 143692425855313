import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "../Images/back.svg";
import { ReactComponent as Logo } from "../Images/Logo-with-text.svg";
import logotxt from "../Images/Logo.svg";
import WhatsApp from "../Images/whatsapp.svg";
import mail from "../Images/mail-02.svg";
import call from "../Images/call.svg";
import cancel from "../Images/Cancel.svg";
import triangle from "../Images/triangleHelp.svg";
import { useFormik } from "formik";
import { loginMailValidator } from "../../utils/services/validation";
import AuthRequest from "../../utils/services/authRequests";
import SuccessAlert from "../shared/Alerts/success_alert";
import UserRequest from "../../utils/services/userRequets";
import { useAppDispatch } from "../../utils/redux/store";
import { setUser } from "../../utils/redux/authSlice";
import TextInput from "../shared/input/TextInput";
import { toast } from "react-toastify";
import user from "../Images/userID.png";

const contact = [
  {
    id: 1,
    name: "Open WhatsApp",
    icon: WhatsApp,
    type: "whatsapp",
    contact: "+2348163153270",
  },
  {
    id: 2,
    name: "Send an Email",
    icon: mail,
    type: "email",
    contact: "ije@ijefund.com",
  },
  {
    id: 3,
    name: "Call Us",
    icon: call,
    type: "tel",
    contact: "+2348163153270",
  },
];
function Login() {
  const [view, setView] = useState(false);
  const [success, setSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [help, setHelp] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formik = useFormik({
    validationSchema: loginMailValidator,
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      await AuthRequest.userLogin(values.email, values.password).then(async (res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          localStorage.setItem("ijeToken", res.data.result.accessToken);

          await UserRequest.getUserDetials(values.email).then((res) => {
            setLoading(false);

            if (res.data.statusCode === 200) {
              localStorage.setItem("ijeDetails", JSON.stringify(res.data.result));
              dispatch(setUser({ ...res.data.result }));
              setSuccess(true);
              toast.success("successful", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              // console.log(res.data.result.roles[0]);
              if (res.data.result.roles[0] === "CUSTOMER") {
                navigate("/user/dashboard");
              } else {
                navigate("/approver/dashboard");
              }
            } else {
              setErrorMessage(res.data.message);
              toast.error(res?.data?.message || res?.message, {
                position: "top-right",
                autoClose: 2000,
                theme: "colored",
              });
              setSuccess(false);
            }
          });
        } else {
          // console.log("error");
          setErrorMessage(res.data.message);
          toast.error(res?.data?.message || res?.message, {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
          setSuccess(false);
        }
        //
      });
    },
    enableReinitialize: true,
  });

  const isFormValid = formik.values.email && formik.values.password;
  return (
    <>
      <div className=" bg-white 100:flex-col lg:flex-row 100:h-screen w-full xl:h-screen 100:justify-start 100:items-center flex xl:justify-start xl:items-start p-[30px]">
        <div className=" 100:hidden xl:flex bg-[#0A205C] fixed top-[20px] left-[30px] xl:w-[685px]  rounded-[30px] p-[50px]  flex-col justify-between h-[95%] ">
          <div className=" flex w-full flex-col justify-start items-start gap-[53px]">
            <Link onClick={() => window.scrollTo(0, 0)} to="/">
              <Logo style={{ color: "#fff" }} />{" "}
            </Link>
            <div className=" flex w-full flex-col justify-start items-start gap-[32px]">
              <h2 className=" font-semibold text-[60px] leading-[110%] tracking-[-2px] text-[#fff] ">Welcome Back to Ije</h2>
              <p className=" font-normal text-[18px] leading-[145%]  text-[#E5E7E8] ">Log in to access your personalized dashboard, manage your campaigns, and connect with supporters.</p>
            </div>
          </div>
          <div className=" flex-col flex justify-start items-start gap-[16px] bg-[#56A1FF] h-[150px] p-[20px] w-full rounded-[20px]">
            <p className=" font-normal text-[16px]  leading-[145%]  text-[#D5EFFF] ">Ije helped me to secure the funding I needed to study in the UK for my masters degree. Thanks to Ije for helping me make my dream a reality.</p>
            <div className="flex w-full gap-[12px] justify-start items-center">
              <img src={user} alt="User" />
              <span className="flex flex-col w-full gap-[5px] justify-start items-start">
                <h4 className=" font-normal text-[14px] leading-[110%]  text-[#fff] ">Temitope Ogunsanya</h4>
                <p className=" font-normal text-[10px] leading-[110%]  text-[#E5E7E8] ">United Kingdom</p>
              </span>
            </div>
          </div>
        </div>
        <div className=" 100:flex xl:hidden bg-[#0A205C] fixed top-[20px] w-[90%] gap-[12px]  rounded-[30px] p-[24px] item-center justify-center h-[75px] ">
          <div className=" flex justify-start items-center ">
            <Link onClick={() => window.scrollTo(0, 0)} to="/">
              <img className=" " src={logotxt} alt="IJE" />
            </Link>
          </div>
          <h2 className=" font-semibold text-[20px] leading-[110%]  text-[#fff] ">Welcome Back to Ije</h2>
        </div>
        <div className="100:w-[90%] 100:pt-[100px] xl:pt-[50px] h-fit xl:w-full gap-[70px] xl:pl-[715px] flex flex-col justify-center items-center py-[50px] pr-[10px]">
          <div className=" w-full flex justify-between items-start ">
            <Link onClick={() => window.scrollTo(0, 0)} to={"/"}>
              <div className="text-[#0C4AFF] gap-[8px] flex justify-start items-center ">
                <Back />
                <h4 className="  text-[16px] font-medium leading-[24px] ">Back</h4>
              </div>
            </Link>
            <button onClick={() => setHelp(!help)} className={`px-1 ${help ? "  bg-[#e8f7ff] rounded-[99px]" : ""} text-[#565c64] text-base font-medium font-inter leading-normal`}>
              Need Help?
            </button>
          </div>
          <div className=" 100:w-full sm:w-[480px] gap-[32px] flex-col flex justify-start items-start ">
            <h4 className=" font-semibold text-[30px] text-[#3A3C3F] leading-[38px] ">Login</h4>
            <div className=" w-full flex justify-center items-center">{success !== null && <SuccessAlert success={success} info={success === true ? "     Welcome back! You have successfully logged in." : errorMessage} />}</div>
            <div className="flex-col  w-full flex gap-[32px] justify-start items-start ">
              <form className="flex-col w-full flex gap-[24px] justify-start items-start ">
                <TextInput label={"Email"} onBlur={formik.handleBlur("email")} type="email" value={formik.values.email} onChange={formik.handleChange("email")} placeholder="Email your address" error={formik.touched.email && formik.errors.email} />

                <TextInput
                  label={"Password"}
                  type={view ? "text" : "password"}
                  onChange={formik.handleChange("password")}
                  onBlur={formik.handleBlur("password")}
                  value={formik.values.password}
                  placeholder="Enter your password"
                  // password
                  error={formik.touched.password && formik.errors.password}
                />

                <div className="w-full justify-end items-end flex">
                  {/* <label className="gap-[8px] flex justify-start items-center leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="save-data">
                    <input className="border rounded-[8px] w-[16px] h-[16px] outline-[#0C4AFF] bg-white border-[#CDD1D4]" type="checkbox" />
                    Remember for 30 days
                  </label> */}
                  <Link onClick={() => window.scrollTo(0, 0)} to="/auth/forgot-password">
                    <div className="  leading-[20px] text-[#0C4AFF] text-[14px] font-medium ">Forgot password</div>
                  </Link>
                </div>
              </form>
              <div className=" w-full flex gap-[16px] flex-col justify-center items-center">
                <button
                  onClick={formik.handleSubmit}
                  disabled={!isFormValid}
                  className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-7 py-4 w-full "
                >
                  {isLoading ? "Loading..." : "Login"}
                </button>
              </div>
              <div className="   text-[#6C757D] leading-[20px] text-[14px] gap-[4px] items-center flex justify-start font-medium  w-full ">
                Don’t have an account?{" "}
                <Link onClick={() => window.scrollTo(0, 0)} className="text-[#0C4AFF]" to="/auth/register">
                  Sign up
                </Link>
              </div>
            </div>
          </div>
          {help && (
            <>
              <div className=" absolute 100:right-[40px] sm:right-20 lg:right-10 100:top-[160px] lg:top-[110px]  justify-end items-end  flex flex-col 100:w-[85%] phone:w-[416px] h-fit">
                <img className="w-[70px] mb-[-30px] h-[70px]" src={triangle} alt="" />
                <div className="h-fit w-full px-6 py-5 bg-[#0a205c] rounded-xl shadow flex-col justify-start items-center gap-2.5 inline-flex">
                  <div className="w-full justify-end items-center flex">
                    <div onClick={() => setHelp(false)} className="w-[36px] cursor-pointer h-[36px] bg-[#103a9f] rounded-[100px] flex-col justify-center items-center flex">
                      <img className=" " src={cancel} alt="Cancel" />
                    </div>
                  </div>
                  <div className="w-full flex-col justify-start items-center gap-4 inline-flex">
                    <div className="w-full flex-col justify-center items-center gap-2 flex">
                      <h2 className="self-stretch text-center text-[#f5f6f6] text-lg font-medium font-inter leading-7">How can we assist you?</h2>
                      <p className="self-stretch text-center text-[#aab0b6] text-sm font-normal font-inter leading-tight">
                        Choose how you'd like to reach us—via WhatsApp, email, or a direct call. <span className="text-[#e5e7e8] text-sm font-medium ">We're here to help!</span>
                      </p>
                    </div>
                    <div className="w-full py-2.5 100:justify-center sm:justify-start 100:flex-wrap sm:flex-nowrap items-start gap-3 inline-flex">
                      {contact.map((item) => (
                        <>
                          {item.type === "email" ? (
                            <a href={`mailto:${item.contact}`} key={item.id} className="h-11 w-fit p-2.5 bg-[#103a9f] rounded-[999px] border border-[#cdd1d4] justify-start items-center  inline-flex gap-2.5">
                              <h3 className="text-[#f5f6f6] text-[10px] font-semibold font-inter leading-3">{item.name}</h3>
                              <img src={item.icon} alt={item.name} className="h-5 w-5" />
                            </a>
                          ) : item.type === "tel" ? (
                            <a href={`tel:${item.contact}`} key={item.id} className="h-11 w-fit p-2.5 bg-[#103a9f] rounded-[999px] border border-[#cdd1d4] justify-start items-center  inline-flex gap-2.5">
                              <h3 className="text-[#f5f6f6] text-[10px] font-semibold font-inter leading-3">{item.name}</h3>
                              <img src={item.icon} alt={item.name} className="h-5 w-5" />
                            </a>
                          ) : item.type === "whatsapp" ? (
                            <a
                              href={`https://wa.me/${item.contact.replace("+", "")}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              key={item.id}
                              className="h-11 w-fit p-2.5 bg-[#103a9f] rounded-[999px] border border-[#cdd1d4] justify-start items-center  inline-flex gap-2.5"
                            >
                              <h3 className="text-[#f5f6f6] text-[10px] font-semibold font-inter leading-3">{item.name}</h3>
                              <img src={item.icon} alt={item.name} className="h-5 w-5" />
                            </a>
                          ) : (
                            <div key={item.id} className="h-11 w-fit p-2.5 bg-[#103a9f] rounded-[999px] border border-[#cdd1d4] justify-start items-center  inline-flex gap-2.5">
                              <h3 className="ttext-[#f5f6f6] text-[10px] font-semibold font-inter leading-3">{item.name}</h3>
                              <img src={item.icon} alt={item.name} className="h-5 w-5" />
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Login;
