import { useState } from "react";
import { ReactComponent as Completed } from "../../Images/complete.svg";
import { ReactComponent as Search } from "../../Images/search.svg";
import { ReactComponent as Pending } from "../../Images/pending.svg";
import { ReactComponent as Reject } from "../../Images/reject.svg";
import { useNavigate } from "react-router-dom";
import { CurrencyFormatter } from "../../../utils/helpers/helper";
const LoanManagementTable = ({ data, onClick }) => {
  const [search, setSearch] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="h-fit w-full whitespace-nowrap overflow-x-scroll bg-white rounded-xl shadow flex-col justify-center items-start gap-[24px] inline-flex">
      <div className="flex justify-between items-center w-full p-[20px]">
        {search ? (
          <div
            onClick={() => setSearch(false)}
            className="self-stretch flex md:hidden gap-[12px] w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  text-[#6c757d] text-base font-normal leading-normal"
          >
            <Search />
            <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
          </div>
        ) : (
          <div
            onClick={() => {
              setSearch(true);
            }}
            className="px-3.5 py-2.5 flex md:hidden"
          >
            <Search />
          </div>
        )}

        <div className="self-stretch gap-[12px] hidden md:flex w-[400px] px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  text-[#6c757d] text-base font-normal leading-normal">
          <Search />
          <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
        </div>
      </div>

      <div className="self-stretch w-full border-t border-t-[#CDD1D4] flex justify-between items-center">
        {["Application ID", "Applicant Name", "Amount Requested", "Status", ""].map((header) => (
          <div key={header} className="w-full flex flex-col justify-start items-start">
            <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium leading-[18px]">{header}</div>
            {data?.map((item, index) => (
              <div key={item?.id} className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"} justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>
                {header === "Application ID" && item?.id}
                {header === "Applicant Name" && <div className="capitalize text-pretty">{item?.name}</div>}
                {header === "Amount Requested" && (
                  <div>
                    {/* {CurrencyFormatter(item.amount), "$"} */}
                    {/* {item.amount} */}
                    {CurrencyFormatter(item?.amount || 0, "$")}
                  </div>
                )}
                {header === "Status" && (
                  <div className={`pl-1.5 pr-2 py-0.5 rounded-2xl justify-center items-center gap-1 flex ${item?.status === "APPROVE" ? "bg-[#f1fcf2]" : item?.status === "REJECTED" ? "bg-red-100" : "bg-[#ffffea]"}`}>
                    {item?.status === "APPROVE" ? <Completed /> : item?.status === "REJECTED" ? <Reject /> : <Pending />}
                    <div className={`text-center ${item?.status === "APPROVE" ? "text-[#1f7634]" : item?.status === "REJECTED" ? "text-red-500" : "text-[#bb6902]"} text-xs font-medium leading-[18px]`}>
                      {" "}
                      {item?.status.charAt(0).toUpperCase() + item?.status.slice(1).toLowerCase()}
                    </div>
                  </div>
                )}

                {header === "" && (
                  <div className="text-[#0c4aff] text-sm font-medium leading-tight flex  gap-4">
                    <button
                      onClick={() => {
                        onClick(item);
                      }}
                    >
                      {"View"}
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoanManagementTable;
