import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminRequest from "../../../utils/services/adminRequests";
import user from "../../Images/userID.png";
import cancel from "../../Images/Cancel.svg";
import { ReactComponent as Eyes } from "../../Images/ImgEyes.svg";

function BasicInfo({ data }) {
  const [viewImage, setviewImage] = useState(false);

  return (
    <>
      <div className="h-fit w-full flex-col justify-start items-start inline-flex">
        <div className=" w-full  100:h-fit 100:flex-col sm:flex-row  sm:h-[600px] sm:pr-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-start items-start gap-8 inline-flex">
          <div className=" 100:w-full   h-full sm:w-[175px]  p-5 bg-[#e5e7e8] rounded-tl-lg md:rounded-bl-lg justify-center items-start gap-2.5 flex">
            <div className=" flex w-fit items-end  justify-end flex-col">
              <img className="w-24 h-24 object-fill rounded-[200px] shadow border-4 border-white" src={data?.imageString ? data.imageString : user} alt={(data?.firstName, data?.lastName)} />
              <div className="h-5 w-5 mt-[-30px]  bg-[#f5f6f6] rounded-[100px] border-4 border-white justify-center items-center gap-2.5 inline-flex">
                <Eyes className=" cursor-pointer" onClick={() => setviewImage(true)} />
              </div>
            </div>
          </div>
          <div className="h-fit 100:px-[24px] sm:px-0 w-full 100:pb-6 sm:py-6 flex-col justify-start items-start gap-4 inline-flex">
            <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Full Name</h4>
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                    {data?.firstName} {data?.lastName}
                  </h3>
                </div>
              </div>
            </div>
            <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Gender</h4>
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{data?.gender ? data?.gender : <div>No Data</div>}</h3>
                </div>
              </div>
            </div>
            <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Date of Birth</h4>
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{data?.dob ? data?.dob : <div>No Data</div>}</h3>
                </div>
              </div>
            </div>
            <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Email Address</h4>
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{data?.email}</h3>
                </div>
              </div>
            </div>
            <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Phone Number</h4>
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{data?.phoneNumber}</h3>
                </div>
              </div>
            </div>
            <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Address</h4>
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{data?.address}</h3>
                </div>
              </div>
            </div>
            {/* <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Bank Verification Number (BVN)</h4>
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{data?.educationInformation?.bvn ? data?.educationInformation?.bvn : <div>No Data</div>}</h3>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {viewImage && (
        <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start isUsers-center flex">
          <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
            <div
              onClick={() => {
                setviewImage(false);
              }}
              className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  "
            >
              <img className=" " src={cancel} alt="Cancel" />
            </div>
          </section>
          <div className=" w-full h-full flex justify-center items-center">
            <div className=" 100:w-[85%] 100:h-fit lg:w-[600px] lg:h-[500px] p-4 bg-white rounded-xl shadow flex-col justify-start items-center gap-8 inline-flex">
              <img className=" object-fill rounded-xl bg-cover w-full h-full " src={data?.imageString ? data.imageString : user} alt={(data?.firstName, data?.lastName)} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BasicInfo;
